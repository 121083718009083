import { Box, AppBar, Toolbar } from "@mui/material"
import * as React from "react"
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ChooseJob from "./chooseJob";

function EditJobDesc({data, updateState}) {
	const [jobList, setJobList] = React.useState([])
	const addJob = (event) => {
		setJobList([...jobList, <ChooseJob data={data} updateState={updateState} key={jobList.length + 1} serial={jobList.length + 1}/>])
	}
	return (
		<Box sx={{ paddingTop: 1 }}>
			<AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', width: '30%' }}>
				<Toolbar>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						职能选择
					</Typography>
					<IconButton edge="end" color="primary" aria-label="add" onClick={addJob}>
						<AddIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<ChooseJob data={data} updateState={updateState} key={0} serial={0}></ChooseJob>
			{jobList}
		</Box>
	);

}

export default EditJobDesc;