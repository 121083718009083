import * as React from "react"
import { Box, FormControl, Grid, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Checkbox } from "@mui/material";


function ChooseJob({data, updateState, serial}) {
	const [dataOfLevel1, setDataOfLevel1] = React.useState([]);
	const [dataOfLevel2, setDataOfLevel2] = React.useState([]);
	const [dataOfLevel3, setDataOfLevel3] = React.useState([]);
	const [dataOfTable, setDataOfTable] = React.useState([]);
	const [allDesc, setAllDesc] = React.useState([]);
	React.useEffect(() => {
		const fetchData = async () => {
			// Replace the URL with your JSON data URL
			const response = await fetch("/job_role_1.json");
			const data = await response.json();
			setDataOfLevel1(data);
		};
		fetchData();
	}, []);
	return (
		<Box>
			<FormControl size="small" sx={{ paddingTop: 1, width: '90%', paddingLeft: 1, paddingBottom: 1, border: "1px black solid" }}>
				<Grid container spacing={0}>
					<Grid xs="4">
						<span>一级职能</span>
						<Select id="jobLevel1" sx={{ width: '60%' }} onChange={handleChangeOfLevel1}>
							{dataOfLevel1.map((item, index) => (
								<MenuItem key={item.id} value={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid xs="4">
						<span>二级职能</span>
						<Select id="jobLevel2" sx={{ width: '60%' }} onChange={handleChangeOfLevel2}>
							{dataOfLevel2.map((item, index) => (
								<MenuItem key={item.id} value={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid xs="4">
						<span>三级职能</span>
						<Select id="jobLevel3" sx={{ width: '60%' }} onChange={handleChangeOfLevel3}>
							{dataOfLevel3.map((item, index) => (
								<MenuItem key={item.id} value={item.name}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			</FormControl>
			<TableContainer  sx={{ paddingTop: 1, width: '90%', paddingLeft: 1, paddingBottom: 1, border: "1px black solid" }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>操作</TableCell>
							<TableCell align="right">关键词</TableCell>
							<TableCell align="left">主要工作职责</TableCell>
							<TableCell align="right">权重</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{dataOfTable.map((row) => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row" padding="checkbox">
									<Checkbox onChange={(e) => checkDesc(row, e)} inputProps={row}></Checkbox>
								</TableCell>
								<TableCell align="right">{row.keyword}</TableCell>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="right">{row.weight}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
	
	function checkDesc(row, event) {
		if (event.target.checked) {
			data.desc.push({...row, id: "" + serial + row.id})
		} else {
			for (let index = 0; index < data.desc.length; index++) {
				const element = data.desc[index];
				if (element.id == ("" + serial + row.id)) {
					data.desc.splice(index, 1)
				}
			}
		}
		// setAllDesc(allDesc)
		updateState({...data, desc: data.desc})
	}

	function handleChangeOfLevel1(event) {
		const fetchData = async () => {
			const response = await fetch("/job_role_2.json");
			const data = await response.json();
			console.log(data, event.target.value)
			for (let index = 0; index < data.length; index++) {
				const element = data[index];
				if (element.parentId === event.target.value) {
					setDataOfLevel2(element.roles)
				}
			}
		};
		fetchData();
	}

	function handleChangeOfLevel2(event) {
		const fetchData = async () => {
			const response = await fetch("/job_role_3.json");
			const data = await response.json();
			console.log(data, event.target.value)
			for (let index = 0; index < data.length; index++) {
				const element = data[index];
				if (element.parentId === event.target.value) {
					setDataOfLevel3(element.roles)
				}
			}
		};
		fetchData();
	}

	function handleChangeOfLevel3(event) {
		const fetchData = async () => {
			const response = await fetch("/job_description.json");
			const dataOfDesc = await response.json();
			console.log(dataOfDesc, event.target.value, data.jobLevel)
			for (let index = 0; index < dataOfDesc.length; index++) {
				const element = dataOfDesc[index];
				if (element.capability == event.target.value && element.level == data.jobLevel) {
					setDataOfTable(element.detail)
					break;
				}
			}
		};
		fetchData();
		const fetchCaps = async () => {
			const response = await fetch("/job_capability.json");
			const caps = await response.json();
			const response1 = await fetch("/job_communication.json");
			const communis = await response1.json();
			let certs, commu;
			for (let index = 0; index < caps.length; index++) {
				const element = caps[index];
				if (element.job == event.target.value && element.level == data.jobLevel) {
					certs = element
					break;
				}
			}
			for (let index = 0; index < communis.length; index++) {
				const element = communis[index];
				if (element.role == event.target.value) {
					commu = element.caps;
					break;
				}
			}
			updateState({ ...data, caps: commu, certificates: certs })
		}
		fetchCaps()
	}
}

export default ChooseJob