import * as React from "react"
import {  AppBar, Grid } from "@mui/material";
import EditBasicInfo from "./left/basicInfo";
import EditJobDesc from "./left/editJobDesc";

function LeftBar({data, updateState}) {
	console.log(data, updateState)
	return (
		<Grid xs={6}>
			<AppBar position="">信息选择栏</AppBar>
			<EditBasicInfo data={data} updateState={updateState}></EditBasicInfo>
			<EditJobDesc data={data} updateState={updateState}></EditJobDesc>
		</Grid>
	);
}

export default LeftBar;
