import * as React from "react"
import { Grid } from "@mui/material";
import LeftBar from "./leftBar";
import Content from "./content";

function App() {
  const [data, setData]= React.useState({
    jobLevel:"P1",
    certificates: {},
    caps: [],
    desc: [],
    jobRole1: "",
    jobRole2: "",
    jobRole3: ""
  })
  return (
    <Grid container spacing={1}>
      <LeftBar data={data} updateState={setData}></LeftBar>
      <Content data={data} updateState={setData}></Content>
    </Grid>
  );
}

export default App;
