import { Box, Grid, TextField, AppBar, FormControl, Select, MenuItem } from "@mui/material"
import * as React from "react"

function EditBasicInfo({data, updateState}) {
	console.log("EditBasicInfo",data)
	const [jobLevel, setJobLevel] = React.useState([])
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch("/jd_level.json");
			const data = await response.json();
			setJobLevel(data);
		};
		fetchData();
	}, []);
	return (
		<Box>
			<AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', width: '30%', height: 'auto' }}>基本信息</AppBar>
			<FormControl size="small" sx={{ paddingTop: 1, width: '90%', paddingLeft: 1, paddingBottom: 1,  border: "1px black solid" }}>
				<Grid container spacing={0}>
					<Grid xs="2">
						功能块
					</Grid>
					<Grid xs="4">
						<TextField id="function-block" variant="standard" />
					</Grid>
					<Grid xs="2">
						所属部门
					</Grid>
					<Grid xs="4">
						<TextField id="department" variant="standard" />
					</Grid>
					<Grid xs="2">
						职位名称
					</Grid>
					<Grid xs="4">
						<TextField id="job" variant="standard" />
					</Grid>
					<Grid xs="2">
						职级
					</Grid>
					<Grid xs="4">
						<Select id="professionValue" size="small" sx={{ width: '60%' }} onChange={selectJobLevel} defaultValue='P1'>
							{jobLevel.map((item, index) => (
								<MenuItem key={item} value={item}>
									{item}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid xs="2">
						直接上级
					</Grid>
					<Grid xs="4">
						<TextField id="boss" variant="standard" />
					</Grid>
					<Grid xs="2">
						姓名
					</Grid>
					<Grid xs="4">
						<TextField id="candidateName" variant="standard" />
					</Grid>
				</Grid>
			</FormControl>
		</Box>
	);

	function selectJobLevel(event) {
		data.jobLevel=event.target.value
		updateState({...data, jobLevel:event.target.value})
	}
}

export default EditBasicInfo;