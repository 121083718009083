import * as React from "react"
import { Grid, AppBar } from "@mui/material";
import JdBasicInfo from "./right/jdBasicInfo";
import JdDescription from "./right/jdDescription";
import JdCommunication from "./right/jdCommunication";
import JdCertificates from "./right/jdCertificates";

function Content({data}) {
	return (
		<Grid xs={6}>
			<AppBar position="" sx={{backgroundColor: 'grey'}}>岗位描述-标准版</AppBar>
			<JdBasicInfo data={data}></JdBasicInfo>
			<JdDescription data={data}></JdDescription>
			<JdCommunication data={data}></JdCommunication>
			<JdCertificates data={data}></JdCertificates>
		</Grid>
	);
}

export default Content;
