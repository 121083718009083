import { AppBar, Grid, Box } from '@mui/material'
import {makeStyles} from "@mui/styles"
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    border: '1px solid black', 
    boxSizing: 'border-box',
	borderLeft: 'none',
	borderBottom: 'none'
  },
  gridLeft: {
	borderLeft: '1px solid black'
  },
  gridBottome: {
	borderBottom: '1px solid black'
  }
}));

function JdBasicInfo({data}) {
	const classes = useStyles()
	return (
		<Box>
			<AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', height: 'auto' }}>基本信息</AppBar>
			<Grid container>
				<Grid item xs="2" className={classes.gridItem}>功能块</Grid>
				<Grid item xs="4" className={classes.gridItem}></Grid>
				<Grid item xs="2"className={classes.gridItem}>所属部门</Grid>
				<Grid xs="4" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>职位名称</Grid>
				<Grid xs="4" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>职位级别</Grid>
				<Grid xs="4" className={classes.gridItem}>{data.jobLevel}</Grid>
				<Grid xs="2" className={classes.gridItem}>一级职称</Grid>
				<Grid xs="2" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>二级职称</Grid>
				<Grid xs="2" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>三级职称</Grid>
				<Grid xs="2" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>直属上级</Grid>
				<Grid xs="4" className={classes.gridItem}></Grid>
				<Grid xs="2" className={classes.gridItem}>专业能力值</Grid>
				<Grid xs="4" className={classes.gridItem} ></Grid>
			</Grid>
		</Box>
	)
}

export default JdBasicInfo