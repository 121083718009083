import { AppBar, Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import { makeStyles } from "@mui/styles"
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            border: '1px solid black'
        },
        lineHeight: 'normal'
    }
}));

function JdDescription({data}) {
    const classes = useStyles()
    return (
        <Box>
            <AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', height: 'auto' }}>岗位职责</AppBar>
            <TableContainer>
                <Table sx={{ padding: 0, margin: 0, width: '100%' }} size='small' className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="15%">关键词</TableCell>
                            <TableCell width="75%" colSpan={2} align="center">主要工作职责</TableCell>
                            <TableCell width="10%" align="center">权重</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.desc.map((row, index) => (
                            <TableRow>
                                <TableCell>{row.keyword}</TableCell>
                                <TableCell width="5%">{index + 1},</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.weight}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default JdDescription
