import { AppBar, Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import { makeStyles } from "@mui/styles"
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            border: '1px solid black'
        },
        lineHeight: 'normal',
    },
    tr: {
        height: 'auto'
    },
    td: {
        height: 'auto',
        padding: '0px 16px !important'
    }
}));

function JdCommunication({data}) {
    const classes = useStyles()
    return (
        <Box>
            <AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', height: 'auto' }}>业务接触</AppBar>
            <TableContainer>
                <Table sx={{ padding: 0, margin: 0, width: '100%' }} size='small' className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.td} width="20%">范围划分</TableCell>
                            <TableCell className={classes.td} width="30%" align="center">对象</TableCell>
                            <TableCell className={classes.td} width="30%" align="center">事项</TableCell>
                            <TableCell className={classes.td} width="20%" align="center">频率</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={3}>内部</TableCell>
                            <TableCell>{data.caps[0] && data.caps[0].target}</TableCell>
                            <TableCell>{data.caps[0] && data.caps[0].event}</TableCell>
                            <TableCell>{data.caps[0] && data.caps[0].rate}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{data.caps[1] && data.caps[1].target}</TableCell>
                            <TableCell>{data.caps[1] && data.caps[1].event}</TableCell>
                            <TableCell>{data.caps[1] && data.caps[1].rate}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={3}>外部</TableCell>
                            <TableCell>{data.caps[2] && data.caps[2].target}</TableCell>
                            <TableCell>{data.caps[2] && data.caps[2].event}</TableCell>
                            <TableCell>{data.caps[2] && data.caps[2].rate}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default JdCommunication

