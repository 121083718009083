import { AppBar, Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import { makeStyles } from "@mui/styles"
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            border: '1px solid black'
        },
        lineHeight: 'normal',
    },
    tr: {
        height: 'auto'
    },
    td: {
        height: 'auto',
        padding: '0px 16px !important'
    }
}));

function JdCertificates({data}) {
    console.log(data)
    const [cert] = React.useState(data)
    const classes = useStyles()
    return (
        <Box>
            <AppBar position="" sx={{ backgroundColor: 'rgb(28 47 112)', height: 'auto' }}>任职资格</AppBar>
            <TableContainer>
                <Table sx={{ padding: 0, margin: 0, width: '100%' }} size='small' className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={2} width='25%'>教育背景</TableCell>
                            <TableCell width='25%'>最低学历要求</TableCell>
                            <TableCell width='25%'>最佳学历背景</TableCell>
                            <TableCell width='25%'>所需专业背景</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{data.certificates.background}</TableCell>
                            <TableCell>{data.certificates.minEduLevel}</TableCell>
                            <TableCell>{data.certificates.maxEduLevel}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>行业/学术经验</TableCell>
                            <TableCell  colSpan={3}>{data.certificates.academyExp}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>职业经验</TableCell>
                            <TableCell  colSpan={3}>{data.certificates.professionExp}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2}>海外经验</TableCell>
                            <TableCell>是否需要</TableCell>
                            <TableCell colSpan={2}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{data.certificates.abroad}</TableCell>
                            <TableCell colSpan={2}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table sx={{ padding: 0, margin: 0, width: '100%' }} size='small' className={classes.table}>
                    <TableBody>
                        {data.certificates.caps && (
                        <TableRow>
                            <TableCell rowSpan={6} width='15%'>专业能力</TableCell>
                            <TableCell colSpan={2} width='70%'>专业技能</TableCell>
                            <TableCell width='15%'>程度</TableCell>
                        </TableRow>)}
                        {data.certificates.caps && data.certificates.caps.map((item, index) => (
                            <TableRow>
                                <TableCell width='5%'>{index + 1},</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.degree}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={2}>语言能力</TableCell>
                            <TableCell>1,</TableCell>
                            <TableCell>{data.certificates.langs && data.certificates.langs[0]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>2,</TableCell>
                            <TableCell>{data.certificates.langs && data.certificates.langs[1]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={5}>通用能力</TableCell>
                            <TableCell>1,</TableCell>
                            <TableCell>{data.certificates.commonAbiliity && data.certificates.commonAbiliity[0]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>2,</TableCell>
                            <TableCell>{data.certificates.commonAbiliity && data.certificates.commonAbiliity[1]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>3,</TableCell>
                            <TableCell>{data.certificates.commonAbiliity && data.certificates.commonAbiliity[2]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>4,</TableCell>
                            <TableCell>{data.certificates.commonAbiliity && data.certificates.commonAbiliity[3]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>5,</TableCell>
                            <TableCell>{data.certificates.commonAbiliity && data.certificates.commonAbiliity[4]}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>专业/培训证书</TableCell>
                            <TableCell></TableCell>
                            <TableCell colSpan={2}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>其它要求</TableCell>
                            <TableCell></TableCell>
                            <TableCell colSpan={2}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default JdCertificates